<!--选择与会员工-->
<template>
  <div class="selectPa">
    <div class="selectPa-container" :class="{'hasborder': hasborder===true}">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="12" class="ma-0 pa-0 text-style text">
          <div class="title x-title">
            <span v-if="selectType=='normal'">Select Participants</span>
            <span v-if="selectType=='setting'">Manage Delegates</span>
            <span v-if="selectType=='booking'">Select User</span>
            <i class="iconfont icon-chahao" style="float:right;margin-right: 40px;cursor: pointer"
               @click="backToBooking"></i>
          </div>
        </v-col>
      </v-row>
      <!--主体内容-->
      <div class="content">
        <div class="des x-background">
          <p v-if="selectType=='normal'">
            Type the names of people you would like to invite to your meeting. Check the box next to
            a participant to
            select them.
          </p>
          <p v-if="selectType=='setting'">
            Type the names of people you would like to set as Delegates. They will be able to book
            rooms and desks on
            your behalf.
          </p>
          <p v-if="selectType=='booking'">
            Type a name to search for the User for whom
            you’d like to create the booking. Tap ‘Select

            User’ to confirm.
          </p>
          <searchBox ref="searhBox" @search="search" @keypress.enter.native.prevent></searchBox>
        </div>
        <div class="current-select">
          <p class="current-select-title">Currently Selected</p>
          <!--参加会议的名单-->
          <div class="paArr">
            <div class="participant" v-for="item in paArr">
              <span v-if="item.name">{{ item.name }}</span>
              <span v-else>{{ item.firstName + ' ' + item.lastName }}</span>
              <i class="iconfont icon-chahao" @click="deletePa(item)"></i>
            </div>
          </div>
          <v-btn rounded class="x-btn" @click="addPa" v-if="selectType=='normal'">Add Participants
          </v-btn>
          <v-btn rounded class="x-btn" @click="addPa" v-if="selectType=='setting'">Update
            Delegates
          </v-btn>
          <v-btn rounded class="x-btn" @click="addPa" v-if="selectType=='booking'">Select User
          </v-btn>
        </div>
        <!--员工列表-->
        <div v-show="showStaffList" class="content staffList">
          <staff v-for="(item,index) in attendees"
                 :ref="'staff'+index"
                 :data="item"
                 :showCheckbox="true"
                 style="cursor: pointer"
                 class="staff"
                 @selectPa="selectPa"
          ></staff>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchBox from "@/views/dashboard/componentsHa/searchBox";
import staff from "@/views/dashboard/componentsHa/staff"
import axios from "axios";

export default {
  name: "selectPa",
  components: {
    searchBox,
    staff
  },
  props: {
    pa: {
      type: Array,
      default: []
    },
    hasborder: {
      type: Boolean,
      default: true
    },
    selectType: {
      type: String,
      default: 'normal'
    },
    isDelegate: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    pa(newVal, oldVal) {
      let arr = [];
      newVal.map(item => {
        let obj = {};
        obj.id = item.attendeeId || item.id;
        obj.email = item.email;
        obj.name = item.name;
        obj.firstName = item.name.split(" ")[0];
        obj.lastName = item.name.split(" ")[1];
        obj.photo = item.photo;
        arr.push(obj);
      })
      this.paArr = arr;
    }
  },
  data() {
    return {
      cancel: null,
      showStaffList: true,
      attendees: [],//搜索出的员工
      attendeesOrigin: [],
      timeOut: null,
      paArr: [],
    }
  },
  methods: {
    // booking page 获取可以帮他人预订的授权用户列表
    delegateForUsers() {
      this.$axios({
        method: 'get',
        url: '/m/spaces/delegateForUsers',
      }).then(res => {
        console.log('delegateForUsers:', res);
        this.attendees = res.data.data;
        this.attendeesOrigin = JSON.parse(JSON.stringify(res.data.data));
      }).catch(err => {
        console.log(err);
      })
    },
    // 提交与会人员
    addPa() {
      console.log('this.selectType：', this.selectType);
      console.log("0000000", this.paArr)
      if (this.paArr.length <= 0) {
        this.$confirm({
          title: "Confirmation",
          message: "Please select a User",
          show: true
        })
        return
      }
      this.$emit("addPa", this.paArr);
      this.$refs.searhBox.name = '';//清空搜索
      if (this.selectType == 'booking') {
        return
      } else {
        this.paArr = [];//清空与会人员
        this.attendees = [];//清空搜索列表
      }
    },
    // 删除与会人员
    deletePa(data) {
      console.log("删除：", data);
      console.log("attendees：", this.attendees);
      let length = this.paArr.length;
      if (this.selectType == 'normal') {
        for (let i = 0; i < length; i++) {
          let item = this.paArr[i];
          if (data.email == item.email) {
            this.paArr.splice(i, 1);
            break;
          }
        }
        // 下面搜索列表的勾选同步取消
        let length2 = this.attendees.length;
        for (let i = 0; i < length2; i++) {
          let item = this.attendees[i];
          if (item.email == data.email) {
            console.log("找到了：", item);
            item.chosen = false;
            break
          } else {
            continue
          }
        }
      } else {
        for (let i = 0; i < length; i++) {
          let item = this.paArr[i];
          if (data.id == item.id) {
            this.paArr.splice(i, 1);
            break;
          }
        }
        // 下面搜索列表的勾选同步取消
        let length2 = this.attendees.length;
        for (let i = 0; i < length2; i++) {
          let item = this.attendees[i];
          if (item.id == data.id) {
            console.log("找到了：", item);
            item.chosen = false;
            break
          } else {
            continue
          }
        }
      }

      let arr = JSON.parse(JSON.stringify(this.attendees));
      this.attendees = [];
      this.attendees = arr;

      console.log("attendees:", this.attendees);
      // let length2 = this.attendees.length;
      // for (let i = 0; i < length2; i++) {
      //   let item = this.attendees[i];
      //   if (data.id == item.id) {
      //     let rf = 'staff' + i;
      //     this.$refs[rf][0].checkbox = false;
      //   }
      // }
    },
    // 勾选与会人员
    // selectPa(data) {
    //   console.log("paArr:", this.paArr);
    //   // console.log("data:", data);
    //   if (this.paArr.length == 0) {
    //     this.paArr.push(data);
    //   } else {
    //     if (this.selectType == 'normal' || this.selectType == 'setting') {
    //       let exit = false;
    //       let exitObj = {};
    //       let length = this.paArr.length;
    //       for (let i = 0; i < length; i++) {
    //         console.log("i:", i);
    //         let item = this.paArr[i];
    //         if (this.selectType == 'normal') {
    //           if (item.email == data.email) {
    //             exit = true;
    //             exitObj = item;
    //             break
    //           } else {
    //             continue
    //           }
    //         } else if (this.selectType == 'setting') {
    //           if (item.id == data.id) {
    //             exit = true;
    //             exitObj = item;
    //             break
    //           } else {
    //             continue
    //           }
    //         }
    //       }
    //       console.log("exit:", exit);
    //       if (exit) {
    //         this.deletePa(data);
    //       } else {
    //         this.paArr.push(data);
    //       }
    //     } else {
    //       this.attendees.map(item => {
    //         if (item.id == data.id) {
    //           item.chosen = true;
    //         } else {
    //           item.chosen = false;
    //         }
    //       })
    //       let arr = [];
    //       arr.push(data);
    //       this.paArr = arr;
    //     }
    //   }
    // },
    selectPa(data) {
      console.log("====data =====", data)
      console.log("paArr:", this.paArr);
      // console.log("data:", data);
      if (this.paArr.length == 0) {
        this.paArr.push(data);
      } else {
        let exit = false;
        let exitObj = {};
        let length = this.paArr.length;
        for (let i = 0; i < length; i++) {
          console.log("i:", i);
          let item = this.paArr[i];
          if (this.selectType == 'normal') {
            if (item.email == data.email) {
              exit = true;
              exitObj = item;
              break
            } else {
              continue
            }
          } else {
            if (item.id == data.id) {
              exit = true;
              exitObj = item;
              break
            } else {
              continue
            }
          }
        }
        console.log("exit:", exit);
        if (exit) {
          this.deletePa(data);
        } else {
          console.log("==== 111111111=====",)
          if (this.selectType == 'booking') {
            console.log("==== 222222222=====",)

            this.attendees.map((item, index) => {
              this.$set(this.attendees[index], "chosen", false);
            })
            this.attendees.map(item => {
              if (item.id == data.id) {
                item.chosen = true;
              } else {
                item.chosen = false;
              }
            })
            console.log("====attendees =====", this.attendees)
            let arr = [];
            arr.push(data);
            this.paArr = arr;
          } else {
            this.paArr.push(data);
          }
        }

      }
    },
    backToBooking() {
      this.$emit("backToBooking", true);
      this.$refs.searhBox.name = '';//清空搜索
      if (this.selectType == 'booking') {
        return
      } else {
        this.paArr = [];//清空与会人员
        this.attendees = [];//清空搜索列表
      }
    },
    // 搜索输入框
    search(name) {
      // console.log('name:', name)
      if (this.cancel) {
        this.cancel();
      }
      // 定义CancelToken，它是axios的一个属性，且是一个构造函数
      let CancelToken = axios.CancelToken;
      if (name == '') {
        if (this.selectType == 'normal' || this.selectType == 'setting') {
          this.showStaffList = false;
        } else {
          this.showStaffList = true;
        }
      } else {
        this.showStaffList = true;
      }
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }
      if (this.selectType == 'normal' || this.selectType == 'setting') {
        console.log("====normal or setting=====",)
        this.timeOut = setTimeout(() => {
          let url = "";
          url = this.$usev2 ? "/m/v2/attendees/": "/m/attendees/";
          // url = "/m/v2/attendees/";
          axios({
            method: 'GET',
            url: url,　　　　　　　　//利用了字符串模板来携带id
            data: {
              meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : '',
              keyword: name,
              token: window.localStorage.getItem('token')
            },
            cancelToken: new CancelToken((c) => {
              this.cancel = c;
            })
          }).then(res => {
            this.attendees = res.data.attendees;
            console.log("attendees:", this.attendees);
            console.log("paArr:", this.paArr);
            if (this.selectType == 'normal') {
              this.attendees.map(item => {
                this.paArr.map(chosen => {
                  if ((chosen.email == item.email) || (chosen.id == item.id)) {
                    item.chosen = true;
                  }
                })
              })
            } else if (this.selectType == 'setting') {
              this.attendees.map(item => {
                this.paArr.map(chosen => {
                  if (chosen.id == item.id) {
                    item.chosen = true;
                  }
                })
              })
            }
          }).catch(err => {
            console.log(err)
          })
        }, 300)
      } else {
        console.log("====Delegate Booking =====",)
        let isAdmin = localStorage.getItem("cmsAdmin")
        // cms跳转进来的（admin账号）
        if (isAdmin == 'true') {
          console.log("==== isAdmin=====",)
          this.timeOut = setTimeout(() => {
            let url = "";
            url = this.$usev2 ? "/m/v2/attendees/": "/m/attendees/";
            // url = "/m/v2/attendees/";
            axios({
              method: 'GET',
              url: url,　　　　　　　　//利用了字符串模板来携带id
              data: {
                meetingId: localStorage.meetingIdMap ? localStorage.meetingIdMap : '',
                keyword: name,
                token: window.localStorage.getItem('token')
              },
              cancelToken: new CancelToken((c) => {
                this.cancel = c;
              })
            }).then(res => {
              this.attendees = res.data.attendees;
              // 默认所有的人员是没选中
              this.attendees.map((item, index) => {
                this.$set(this.attendees[index], "chosen", false);
              })

              console.log("attendees:", this.attendees);
              console.log("paArr:", this.paArr);
              if (this.selectType == 'normal') {
                this.attendees.map(item => {
                  this.paArr.map(chosen => {
                    if ((chosen.email == item.email) || (chosen.id == item.id)) {
                      item.chosen = true;
                    }
                  })
                })
              } else if (this.selectType == 'setting') {
                this.attendees.map(item => {
                  this.paArr.map(chosen => {
                    if (chosen.id == item.id) {
                      item.chosen = true;
                    }
                  })
                })
              } else if (this.selectType == 'booking') {
                this.attendees.map(item => {
                  this.paArr.map(chosen => {
                    if (chosen.id == item.id) {
                      item.chosen = true;
                    }
                  })
                })
              }
            }).catch(err => {
              console.log(err)
            })
          }, 300)
        } else {
          // 非admin账号
          this.timeOut = setTimeout(() => {
            if (name == '') {
              this.attendees = this.attendeesOrigin;
              let length = this.attendees.length;
              for (let i = 0; i < length; i++) {
                let item = this.attendees[i];
                let length2 = this.paArr.length;
                for (let i = 0; i < length2; i++) {
                  let jtem = this.paArr[i];
                  if (item.id == jtem.id) {
                    item.chosen = true;
                    break
                  }
                }
              }
            } else {
              let arr = [];
              this.attendeesOrigin.map((item, index) => {
                console.log("====item =====", item.name)
                if (item.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
                  arr.push(item);
                }
              })
              this.attendees = arr;
              let length = this.attendees.length;
              for (let i = 0; i < length; i++) {
                let item = this.attendees[i];
                let length2 = this.paArr.length;
                for (let i = 0; i < length2; i++) {
                  let jtem = this.paArr[i];
                  if (item.id == jtem.id) {
                    item.chosen = true;
                    break
                  }
                }
              }

              console.log("====this.attendeesOrigin =====", this.attendeesOrigin)
            }
          }, 300)
        }
      }
    },
  },
  created() {
    if (this.selectType == 'booking') {
      let isAdmin = localStorage.getItem("cmsAdmin")
      if (isAdmin == 'true') {
        return
      } else {
        this.delegateForUsers();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.selectPa {
  .selectPa-container {
    &.hasborder {
      border: 1px solid #efefef;
    }

    //border: 1px solid #efefef;
    //width: calc(100% + 6px);
    //width: calc(100% + 19px);
    .title {
      //background: $main-blue;
      color: #ffffff;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .content {
      height: 780px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #fff;
      //width:calc(100% + 15px);
      .des {
        font-size: 15px;
        padding: 20px;
        //background: $main-blue;
        color: #fff;

        p {
          margin-bottom: 20px;
        }
      }

      .current-select {
        background: #f3f3f3;
        padding: 10px;

        .current-select-title {
          font-size: 16px;
          font-weight: bold;
        }

        .paArr {
          .participant {
            display: flex;
            justify-content: space-between;

            span {
              font-weight: bold;
            }

            i {
              cursor: pointer;
            }
          }
        }

        button {
          display: block;
          margin: auto;
        }
      }

      .staff:nth-child(odd) {
        background: #fff;
      }

      .staff:nth-child(even) {
        background: #f3f3f3;
      }
    }
  }
}
</style>

